<template>
    <div class="border-t-2 border-b-2 border-black">
        <div class="grid grid-cols-10 gap-4 py-8 border-t-4 border-b-4 border-black my-1">
            <div class="col-span-10 lg:col-span-7 flex flex-col items-start justify-center">
                <AnimatedText
                    text="State of the Art Mentalism Magic"
                    class="text-responsive-4xl font-Kaisei-Decol font-bold"
                />
            </div>
            <div class="col-span-10 lg:col-span-3 flex flex-col lg:items-center justify-center mt-8 lg:mt-0">
                <ElectricksLogo class="w-32 sm:w-40 xl:w-64 xl:h-20" />

                <p class="text-responsive-lg lg:text-center mt-8 mb-4 lg:my-8">
                    High-quality magic tools with one-of-a-kind integrations
                </p>
                <Button to="https://electricks.info" secondary label="See products" />
            </div>
        </div>
    </div>
</template>

<script>
import Button from '@/components/UI/Button.vue';
import AnimatedText from '@/components/Blocks/AnimatedText.vue';
import ElectricksLogo from '@/components/UI/ElectricksLogo.vue';

export default {
    name: 'ElectricksPromo',
    components: {
        ElectricksLogo,
        AnimatedText,
        Button,
    },
};
</script>

<style scoped></style>
