<template>
    <div class="border-t-2 border-b-2 border-black">
        <div class="grid grid-cols-10 py-8 border-t-4 border-b-4 border-black my-1">
            <div class="col-span-10 lg:col-span-6 flex flex-col items-start justify-center">
                <AnimatedText text="Fundraising" class="text-responsive-4xl font-Kaisei-Decol font-bold text-center" />
                <p class="text-2xl mt-2 lg:mt-0">Our fundraising call for Houdini birthplace memorial in Budapest.</p>
            </div>
            <div class="col-span-10 lg:col-span-4 flex flex-col items-start lg:items-center justify-center">
                <p class="lg:text-responsive-sm text-left lg:text-center mb-8 mt-8 lg:mt-0">
                    We aim to install a memorial plaque at the downtown Budapest building where Houdini was born,
                    commemorating one of the greatest illusionists in history. The design and size of the plaque will be
                    determined by the funds raised; should the collected amount fall short, we'll opt for a smaller, but
                    equally meaningful plaque. After our campaign our fundraisers will be displayed on this website.
                </p>
                <Button to="https://electricks.info/life-of-houdini" target="_blank" label="I want to help" />
            </div>
        </div>
    </div>
</template>

<script>
import Button from '@/components/UI/Button.vue';
import AnimatedText from '@/components/Blocks/AnimatedText.vue';

export default {
    name: 'FundRaising',
    components: {
        AnimatedText,
        Button,
    },
};
</script>

<style scoped></style>
