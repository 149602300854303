<template>
    <div class="text-center lg:max-w-[60vw] 3xl:max-w-[1200px] lg:mx-auto pb-20 pt-20">
        <p>
            LifeofHoudini.com endeavors to honor the legacy of one of the most remarkable illusionists in human history,
            on the occasion of the artist's 150th birthday.
        </p>
        <p class="mt-4">
            This site operates as a content mashup wherein the materials presented are collected from various other
            websites and content aggregators, always indicating their URL sources accordingly; none of which are owned
            by us. We do not infringe on copyright, as our intention is solely to showcase these materials for
            non-profit educational purposes under fair use principles.
        </p>
        <p class="mt-4 lg:mx-32">
            If you would like to add a new or remove/edit any of our published content, please do not hesitate to
            contact us:
            <a href="mailto:info@electricks.info" class="underline">info@electricks.info</a>
        </p>
        <p class="mt-4">
            Owned & maintained by <a href="https://electricks.info" target="_blank" class="underline">Electricks</a>
        </p>
        <p class="mt-4">Created by <a href="https://melkweg.hu" target="_blank" class="underline">MelkwegDigital</a></p>
        <div class="mt-20 lg:mt-32 uppercase">{{ date }}</div>
    </div>
</template>

<script>
export default {
    name: 'Footer',
    props: {
        date: {
            type: String,
            required: true,
        },
    },
};
</script>

<style scoped></style>
