<template>
    <span ref="wrapper" class="text-animated" :class="{ animated: isVisible }">
        <span v-for="(word, i) in text.split(' ')" :key="`word-${i}`">
            <span :style="`transition-delay: ${i * 50}ms`">{{ word }}&nbsp;</span>
        </span>
    </span>
</template>

<script>
export default {
    name: 'AnimatedText',
    props: {
        text: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            isVisible: false,
        };
    },
    mounted() {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.intersectionRatio > 0) {
                        observer.unobserve(this.$refs.wrapper);

                        this.isVisible = true;
                    }
                });
            },
            {
                rootMargin: '0px 0px 0px 0px',
            }
        );

        observer.observe(this.$refs.wrapper);
    },
};
</script>

<style scoped></style>
