<template>
    <div class="grid grid-cols-10 gap-4 base-rounding bg-gray-900 text-bone-white">
        <div class="col-span-12 text-center px-8 py-12 lg:py-20 lg:col-span-8 lg:col-start-2">
            <p class="text-responsive-xl font-bold mb-8">“{{ text }}”</p>
            <span>Harry Houdini</span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'QuoteCard',
    props: {
        text: {
            type: String,
            required: true,
        },
    },
};
</script>

<style scoped></style>
