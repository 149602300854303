<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 48.189 48.189"
        enablebackground="new 0 0 48.189 48.189"
    >
        <polygon
            points="33.006,3.761 24.096,24.921 15.185,3.761 0.664,3.761 0.664,44.43 12.545,44.43 12.545,24.921
				13.204,24.921 17.825,37.486 24.096,37.486 30.367,37.486 34.985,24.921 35.646,24.921 35.646,44.43 47.526,44.43 47.526,3.761 "
            :fill="$store.state.globals.isPageBgDark ? '#FFF' : '#000'"
        ></polygon>
    </svg>
</template>

<script>
export default {
    name: 'MelkwegLogo',
};
</script>

<style scoped></style>
