<template>
    <div class="border-t-2 border-b-2 border-black">
        <div class="grid grid-cols-10 py-8 border-t-4 border-b-4 border-black my-1">
            <div class="col-span-10 lg:col-span-6 flex flex-col items-start justify-center">
                <AnimatedText
                    text="The Spirit of Harry invited us to create this memorial page to inspire future generations."
                    class="text-responsive-xl font-Kaisei-Decol font-bold lg:text-center"
                />
            </div>
            <div
                class="col-span-10 lg:col-span-4 flex flex-col items-start lg:items-center justify-center mt-8 lg:mt-0"
            >
                <MelkwegLogo class="w-20 h-20" />
                <p class="text-responsive-lg text-left lg:text-center my-4 lg:my-8">
                    Magical websites by MelkwegDigital
                </p>
                <Button to="https://melkweg.hu" target="_blank" label="Say hello!" />
            </div>
        </div>
    </div>
</template>

<script>
import Button from '@/components/UI/Button.vue';
import AnimatedText from '@/components/Blocks/AnimatedText.vue';
import MelkwegLogo from '@/components/UI/MelkwegLogo.vue';

export default {
    name: 'FundRaising',
    components: {
        AnimatedText,
        MelkwegLogo,
        Button,
    },
};
</script>

<style scoped></style>
