<template>
    <div class="wrapper">
        <div class="media-wrapper base-rounding overflow-hidden" :class="aspectRatioClasses">
            <slot />
        </div>
        <div class="px-4 mt-4 text-base sm:text-lg 3xl:text-xl">{{ caption }}</div>
        <div class="px-4 flex items-center flex-wrap mt-2">
            <div class="flex items-center">
                <div
                    class="w-6 h-6 rounded-full bg-cover bg-center overflow-hidden mr-2 text-base sm:text-lg"
                    :style="`background-image: url(${favicon}); filter: grayscale(1);`"
                ></div>
                <a :href="url" target="_blank" class="font-bold underline text-base sm:text-lg 3xl:text-xl">
                    {{ linkText }}
                </a>
            </div>

            <span class="text-base sm:text-lg 3xl:text-xl">&nbsp;~ {{ sourceText }}</span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'BaseCard',
    props: {
        caption: {
            type: String,
            required: true,
        },
        linkText: {
            type: String,
            required: true,
        },
        sourceText: {
            type: String,
            required: true,
        },
        url: {
            type: String,
            required: true,
        },
        favicon: {
            type: String,
            required: true,
        },
        aspectRatioClasses: {
            type: String,
            required: false,
            default: '',
        },
    },
};
</script>

<style scoped>
.media-wrapper > * {
    @apply w-full h-full object-cover;
}
</style>
