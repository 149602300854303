<template>
    <div ref="wrapper">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'VideoAutoplayObserver',
    mounted() {
        const videoElement = this.$refs.wrapper.querySelector('video');

        if (!videoElement) {
            return;
        }

        const observer = new IntersectionObserver(
            (entries) => {
                if (!entries[0].isIntersecting) {
                    videoElement.pause();
                } else {
                    videoElement.play();
                }
            },
            {
                rootMargin: '0px 0px 0px 0px',
            }
        );

        observer.observe(this.$refs.wrapper);
    },
};
</script>

<style scoped></style>
