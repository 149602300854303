<template>
    <div
        ref="mainWrapper"
        class="wrapper font-serif relative overflow-hidden"
        :class="$store.state.globals.isPageBgDark ? 'bg-dark bg-black text-bone-white' : 'text-black'"
    >
        <div class="container relative pt-12">
            <div class="grid grid-cols-10 gap-x-4 pb-20 lg:pb-32">
                {{/* Title */}}
                <div class="col-span-10 text-center">
                    <AnimatedText
                        id="main-title"
                        text="The Great Harry Houdini"
                        class="text-responsive-4xl font-Kaisei-Decol font-bold"
                    />
                </div>

                <div class="col-span-10 mb-4 mt-4 lg:mt-0">
                    <div class="animating-border h-[1px] ml-auto delay-[200ms]"></div>
                    <div class="grid grid-cols-9 py-2">
                        <div class="col-span-5 sm:col-span-2">
                            <AnimatedText text="Vol I. No. 1." class="text-responsive-sm" />
                        </div>
                        <div class="col-span-9 sm:col-span-5 order-3 sm:order-2 sm:text-center">
                            <AnimatedText
                                :text="`${humanizedDate}, ${romanizedYear}`"
                                class="text-responsive-sm uppercase"
                            />
                        </div>
                        <div class="col-span-4 sm:col-span-2 order-2 sm:order-3 text-right">
                            <AnimatedText text="5 Cents a Copy" class="text-responsive-sm" />
                        </div>
                    </div>
                    <div class="animating-border h-[2px] delay-[400ms]"></div>
                </div>

                {{/* Left column */}}
                <div class="col-span-10 lg:col-span-3 lg:pr-4 content-col delay-[300ms] mt-3 lg:mt-0">
                    <p class="text-right">
                        March 24, <b>1874, Budapest,</b> Hungary - October 31, <b>1926, Detroit,</b> Michigan, US
                    </p>

                    <div class="grid grid-cols-2 gap-12 lg:gap-[5vw] 3xl:gap-[10rem] mt-16 lg:mt-16">
                        <div class="col-span-2 sm:col-span-1 lg:col-span-2">
                            <p class="capitalized-initial text-center mx-auto">
                                Born Erik Weisz in Hungary, known as Harry Houdini, was one of the most famous magicians
                                and entertainers in history. Immigrating to the United States with his family as a
                                child, he later adopted the stage name Harry Houdini.
                            </p>
                        </div>

                        <div class="col-span-2 sm:col-span-1 sm:row-span-2 lg:col-span-2">
                            <BaseCard
                                caption="Houdini hangs from the L.A. Examiner bldg"
                                link-text="Wild About Harry"
                                source-text="YouTube"
                                url="https://www.youtube.com/watch?v=Dt4WgbxDjM4"
                                :favicon="require('@/assets/images/favicons/wildabouthoudini.jpg')"
                                aspect-ratio-classes="aspect-w-4 aspect-h-3"
                            >
                                <video
                                    :src="require('@/assets/videos/highlights.mp4')"
                                    autoplay
                                    playsinline
                                    loop
                                    muted
                                ></video>
                            </BaseCard>
                        </div>

                        <div class="col-span-2 sm:col-span-1 lg:col-span-2">
                            <p class="text-center">
                                Houdini's career took off after he began performing daring escape acts and illusionary
                                feats, earning him the nickname "The Handcuff King" and "The World's Greatest Escape
                                Artist". Throughout his career, he challenged authorities to restrain him with
                                increasingly complex devices, seemingly impossible situations, such as straitjackets,
                                handcuffs, and even underwater restraints – always managing to break free.
                            </p>
                        </div>
                    </div>
                </div>

                {{/* Center column */}}
                <div
                    class="col-span-10 sm:col-span-5 lg:col-span-4 lg:border-r-2 lg:border-l-2 border-black lg:px-4 mt-12 lg:mt-3 content-col delay-[200ms]"
                >
                    <div class="flex flex-col lg:mt-0">
                        <p
                            class="text-responsive-lg text-center lg:text-left order-2 lg:order-1 inclined mt-12 lg:mt-0"
                        >
                            Houdini performing his handcuff escape
                        </p>
                        <div class="mt-4 lg:mt-8 order-3 lg:order-2">
                            <div class="aspect-w-2 aspect-h-3 base-rounding overflow-hidden">
                                <img
                                    :src="require('@/assets/images/houdini_4.png')"
                                    alt="Houdini performing his handcuff escape"
                                    class="w-full h-full object-cover"
                                />
                            </div>
                            <p class="text-responsive-sm mt-4 ml-4 lg:ml-5">
                                The day when Harry Houdini almost died during handcuff stunt in Blackburn
                            </p>
                        </div>
                        <p class="text-responsive-xl font-bold text-center lg:mt-8 order-1 lg:order-3 3xl:px-12">
                            “My brain is the key that sets me free.”
                        </p>
                    </div>
                </div>

                {{/* Right column */}}
                <div
                    class="col-span-10 sm:col-span-5 lg:col-span-3 lg:pl-4 content-col mt-12 sm:mt-12 lg:mt-32 lg:mt-[5vw] delay-[400ms]"
                >
                    <div class="grid grid-cols-1 gap-12 lg:gap-[4vw] 3xl:gap-[10rem]">
                        <p class="text-center">
                            Not only was Houdini the highest-paid performer in the United States during his era, but he
                            also delved into various other pursuits. He ventured into silent film acting, authored
                            books, harbored a fascination with aviation, served as the president of the Society of
                            American Magicians, and notably, was a skeptic of mediums and an active debunker of
                            spiritualists.
                        </p>

                        <p class="text-responsive-xl font-bold">
                            “Never tell the audience how good you are, they will soon find out for themselves.”
                        </p>

                        <BaseCard
                            caption="Rabbit Entertain Five Hospitalized Children (1925)"
                            link-text="Everett Collection"
                            source-text="posterazzi.com"
                            :favicon="require('@/assets/images/favicons/postezzarri.jpg')"
                            url="https://www.alamy.com/young-harry-houdini-1890-image416776007.html"
                        >
                            <img
                                :src="require('@/assets/images/houdini-kid-magic.jpg')"
                                alt="Rabbit Entertain Five Hospitalized Children (1925)"
                            />
                        </BaseCard>
                    </div>
                </div>
            </div>

            <FundRaising />

            <div ref="mainContent" class="grid grid-cols-10 gap-y-12 lg:gap-5 pt-32 lg:pt-8 relative">
                {{/* * */}}
                {{/* * */}}
                {{/* * */}}
                {{/* Triangle */}}
                {{/* * */}}
                <div
                    id="mask"
                    ref="mask"
                    class="right-0 w-screen z-0 translate-y-full bottom-0"
                    :style="{
                        position: 'fixed',
                        zIndex: 0,
                    }"
                >
                    <div
                        class="w-0 h-0 -translate-x-12 lg:translate-x-[-22vw] border-solid border-l-[calc(50vw+50px)] lg:border-l-[100vw] border-r-[calc(50vw+50px)] lg:border-r-[100vw] border-b-[20vh] lg:border-b-[100vh]"
                        :style="{
                            borderLeftColor: 'transparent',
                            borderRightColor: 'transparent',
                            borderBottomColor: 'black',
                        }"
                    ></div>
                    <div class="w-screen h-[300vh] -mt-1 bg-black"></div>
                </div>

                {{/* * */}}
                {{/* * */}}
                {{/* * */}}
                {{/* Second part */}}
                {{/* * */}}

                <div class="col-span-10 sm:col-span-5 lg:pt-[5vw] sm:row-span-2">
                    <p class="text-responsive-2xl font-bold text-center mb-12 lg:hidden">
                        Houdini was not only a renowned illusionist, but also a passionate advocate for the art of
                        magic.
                    </p>
                    <div class="aspect-w-2 aspect-h-3 base-rounding overflow-hidden">
                        <img
                            :src="require('@/assets/images/houdini_2.png')"
                            alt="Houdini performing his handcuff escape"
                            class="w-full h-full object-cover"
                        />
                    </div>
                    <p class="text-responsive-sm lg:hidden mt-2">
                        Houdini performing the suspended straight jacket escape
                    </p>
                </div>

                <div class="col-span-10 sm:col-span-5 lg:px-[7vw] 3xl:px-28 lg:pt-[5vw]">
                    <p class="text-responsive-lg text-center sm:mt-16 lg:mt-0 hidden lg:block">
                        Houdini was not only a renowned illusionist, but also a passionate advocate for the art of
                        magic.
                    </p>
                    <p class="capitalized-initial text-center mx-auto lg:mt-16">
                        Houdini became renowned for several daring escapes throughout his career, including the Daily
                        Mirror challenge, the Milk Can Escape, the Chinese Water Torture Cell, the Straitjacket Escape,
                        and the Buried Alive stunt.
                    </p>
                </div>

                <div
                    id="rememberingHoudini"
                    ref="remembering"
                    class="col-span-10 lg:col-span-5 lg:col-start-6 lg:pt-[5vw]"
                >
                    <p class="text-responsive-xl font-bold text-center my-12 lg:my-0 lg:px-[6vw] 3xl:px-32">
                        “The secret of showmanship consists not of what you really do, but what the mystery-loving
                        public thinks you do.”
                    </p>

                    <AnimatedText
                        v-if="false"
                        text="Remembering Houdini"
                        class="text-responsive-3xl font-Kaisei-Decol font-bold text-center"
                    />
                </div>

                <div class="col-span-10 hidden sm:block">
                    <p>Houdini performing the suspended straight jacket escape</p>
                </div>

                <div class="col-span-10 lg:pb-40">
                    <div class="grid grid-cols-10 gap-y-12 lg:gap-4">
                        <div class="col-span-10 sm:col-span-5 pt-[5vw] order-2 lg:order-1">
                            <p class="text-responsive-xl font-bold text-center lg:mt-8 lg:px-[6vw]">
                                “Anyone who believes in magic is a fool.”
                            </p>
                        </div>

                        <div
                            class="col-span-10 sm:col-span-5 lg:col-span-4 lg:col-start-7 lg:row-span-2 pt-[5vw] order-1 lg:order-2"
                        >
                            <BaseCard
                                caption="Photograph of Houdini, signed to Harry Price (1921)"
                                link-text="University of London "
                                source-text="Senate House Library | University of London"
                                url="https://www.london.ac.uk/about/services/senate-house-library"
                                :favicon="require('@/assets/images/favicons/london-ac-uk.png')"
                                aspect-ratio-classes="aspect-w-9 aspect-h-11"
                            >
                                <img
                                    :src="require('@/assets/images/houdini-prince.jpeg')"
                                    alt="Houdini performing his handcuff escapePhotograph of Houdini, signed to Harry Price"
                                />
                            </BaseCard>
                        </div>

                        <div class="col-span-10 sm:col-span-5 lg:col-span-3 lg:col-start-2 pt-[5vw] order-3">
                            <BaseCard
                                caption="Archive poster"
                                link-text="Wild About Harry"
                                source-text="Library of Congress"
                                url="https://loc.gov/"
                                :favicon="require('@/assets/images/favicons/wildabouthoudini.jpg')"
                                aspect-ratio-classes="aspect-w-2 aspect-h-3"
                            >
                                <img
                                    :src="require('@/assets/images/houdini_spirits.webp')"
                                    alt="Houdini performing his handcuff escape"
                                />
                            </BaseCard>
                        </div>
                    </div>

                    <div class="grid grid-cols-10 gap-4">
                        {{/* * */}}
                        {{/* * */}}
                        {{/* * */}}
                        {{/* * */}}
                        {{/* * */}}
                        {{/* * */}}
                        {{/* * */}}
                        {{/* * */}}
                        {{/* * */}}
                        {{/* * */}}
                        {{/* * */}}
                        {{/* * */}}
                        {{/* * */}}
                        {{/* * */}}
                        {{/* * */}}
                        {{/* * */}}
                        {{/* * */}}
                        {{/* * */}}
                        {{/* * */}}
                        {{/* * */}}
                        {{/* * */}}

                        <div class="col-span-10 pt-[5vw]">
                            <ElectricksPromo />
                        </div>

                        {{/* * */}}
                        {{/* * */}}
                        {{/* * */}}
                        {{/* * */}}
                        {{/* * */}}
                        {{/* * */}}
                        {{/* * */}}
                        {{/* * */}}
                        {{/* * */}}
                        {{/* * */}}
                        {{/* * */}}
                        {{/* * */}}
                        {{/* * */}}
                        {{/* * */}}
                        {{/* * */}}
                        {{/* * */}}
                        {{/* * */}}
                        {{/* * */}}
                        {{/* * */}}
                        {{/* * */}}
                        {{/* * */}}
                        <div class="col-span-10 sm:col-span-5 lg:col-span-3 mt-32 lg:mt-0 lg:hidden">
                            <p class="text-responsive-xl font-bold text-center">
                                Houdini was born as Erik Weisz to a Jewish family, he was one of seven children.
                            </p>
                        </div>

                        <div class="col-span-10 sm:col-span-5 lg:col-span-3 pt-12">
                            <BaseCard
                                caption="Almassy Square, Budapest, around 1894. In the middle of it stands the corner building, which today bears the number 1 Csengery Street. Csengery street used to be Rakos-arok street, whose name appears in Houdini's birth certificate."
                                link-text="HouseOfHoudiniBudapest.hu"
                                source-text="Budapest City Archives"
                                :favicon="require('@/assets/images/favicons/houseofhoudinibudapest.png')"
                                url="http://www.houseofhoudinibudapest.com/research_news/hu/harry-houdini-hu/seta-a-rakos-arok-utca-korul-avagy-hova-is-szuletett-houdini/"
                                aspect-ratio-classes="aspect-w-1 aspect-h-1"
                            >
                                <img
                                    :src="require('@/assets/images/almassy-square.jpg')"
                                    alt="Almassy Square, Budapest, around 1894"
                                />
                            </BaseCard>
                        </div>

                        <div class="col-span-10 sm:col-span-5 lg:col-span-2 lg:col-start-5 pt-12 lg:pt-40 lg:mx-[-3vw]">
                            <BaseCard
                                caption="Chain Bridge in Budapest around 1870, circa 30 minute walk from Houdini birthplace. Although Houdini claimed to have been born on April 6, 1874 in Appleton, Wisconsin (USA), he actually was born in Budapest, Hungary"
                                link-text="Fortepan.hu"
                                source-text="Budapest City Archives"
                                :favicon="require('@/assets/images/favicons/ilyenisvoltbudapest.png')"
                                url="https://www.ilyenisvoltbudapest.hu/ilyen-is-volt/elso-kerulet-varkerulet/item/lanchid-1890-1900-1-kerulet"
                                aspect-ratio-classes="aspect-w-1 aspect-h-1"
                            >
                                <img
                                    :src="require('@/assets/images/chain-bridge.jpg')"
                                    alt="Chain Bridge in Budapest around 1870"
                                />
                            </BaseCard>
                        </div>

                        <div class="col-span-10 sm:col-span-5 lg:col-span-3 lg:col-start-8 pt-12">
                            <p class="text-responsive-xl font-bold text-center mt-8 hidden lg:block">
                                Houdini was born as Erik Weisz to a Jewish family, he was one of seven children.
                            </p>

                            <VideoAutoplayObserver class="lg:pt-32">
                                <BaseCard
                                    caption="The Early Years"
                                    link-text="Fantasma Magic"
                                    source-text="YouTube"
                                    url="https://youtu.be/w7xGbb2_tlE?si=diZyW-YeWh4RI3-u&t=17"
                                    :favicon="require('@/assets/images/favicons/fantasma.jpg')"
                                    aspect-ratio-classes="aspect-w-3 aspect-h-2"
                                >
                                    <video
                                        playsinline
                                        muted
                                        loop
                                        :src="require('@/assets/videos/early-years.mp4')"
                                    ></video>
                                </BaseCard>
                            </VideoAutoplayObserver>
                        </div>

                        <div class="col-span-10 sm:col-span-5 lg:col-span-3 lg:col-start-2 mt-20 lg:mt-32">
                            <p class="capitalized-initial text-center mb-20 lg:mb-40 lg:-mx-16 lg:px-12">
                                The Weisz family moved with the 4 year old Erik from the old continent to America,
                                landed on July 3, 1878, for a better life.
                                <br />
                                <br />
                                The family changed their name to the German spelling Weiss, and Erik became Ehrich.
                            </p>

                            <div class="lg:-mx-12">
                                <BaseCard
                                    caption="Houdini's mother, Cecelia Steiner Weiss (1842-1913)"
                                    link-text="Wild about Houdini"
                                    source-text="A blog by Houdini expert John Cox"
                                    url="https://www.wildabouthoudini.com/p/cecilia-weiss.html"
                                    :favicon="require('@/assets/images/favicons/wildabouthoudini.jpg')"
                                    aspect-ratio-classes="aspect-w-3 aspect-h-4"
                                >
                                    <img
                                        :src="require('@/assets/images/cecilia-weiss.jpg')"
                                        alt="Houdini's mother, Cecelia Steiner Weiss (1842-1913)"
                                    />
                                </BaseCard>
                            </div>
                        </div>
                        <div class="col-span-10 sm:col-span-5 lg:col-span-3 lg:col-start-7 pt-12 lg:pt-44">
                            <div class="lg:-mx-12">
                                <BaseCard
                                    caption="The father, Rabbi Mayer Samuel Weiss, in lawyer's gown (1829-1892)"
                                    link-text="HoudiniLife.com"
                                    source-text="The houdini file"
                                    url="http://www.houdinifile.com/2012/05/who-was-houdinis-father-new-insights.html"
                                    :favicon="require('@/assets/images/favicons/houdinilife.png')"
                                    aspect-ratio-classes="aspect-w-3 aspect-h-4"
                                >
                                    <img
                                        :src="require('@/assets/images/samuel-weiss.jpg')"
                                        alt="Rabbi Mayer Samuel Weiss"
                                    />
                                </BaseCard>
                            </div>
                        </div>

                        <div class="col-span-10 sm:col-span-5 lg:col-span-3 mt-12 lg:mt-40 lg:px-12">
                            <BaseCard
                                caption="The 3-years-old Erik in 1877 with his sister"
                                link-text="Harvard Theatre Collection"
                                source-text="Houghton Library, Harvard University"
                                :favicon="require('@/assets/images/favicons/houghtonlib.png')"
                                url="https://houghtonlib.tumblr.com/post/87591994095/harry-houdini-age-3-1877-and-age-8-1882-tcs"
                                aspect-ratio-classes="aspect-w-2 aspect-h-3"
                            >
                                <img
                                    :src="require('@/assets/images/3-y-old-houdini-with-sister.jpg')"
                                    alt="The 3-years-old Erik in 1877 with his sister"
                                />
                            </BaseCard>
                        </div>

                        <div class="col-span-10 sm:col-span-5 lg:col-span-4 lg:px-[8vw] 3xl:px-40 mt-12 lg:mt-20">
                            <BaseCard
                                caption="Age 8 (1882)"
                                link-text="Harvard Theatre Collection"
                                source-text="Houghton Library, Harvard University"
                                :favicon="require('@/assets/images/favicons/houghtonlib.png')"
                                url="https://houghtonlib.tumblr.com/post/87591994095/harry-houdini-age-3-1877-and-age-8-1882-tcs"
                                aspect-ratio-classes="aspect-w-2 aspect-h-3"
                            >
                                <img :src="require('@/assets/images/8-y-old-houdini.jpg')" alt="Age 8 (1882)" />
                            </BaseCard>
                        </div>

                        <div class="col-span-10 sm:col-span-5 lg:col-span-3 mt-20 lg:-mt-32">
                            <p class="capitalized-initial text-center mb-20 lg:mb-28">
                                Facing severe financial difficulties, the family's children took on employment to
                                contribute to the family's upkeep. Houdini himself engaged in various odd jobs such as
                                selling newspapers, shining shoes, and running errands.
                                <br />
                                <br />
                                When he was nine years old, Houdini and a group of friends set up a five-cent circus,
                                where he sported red woolen stockings and adopted the moniker "Ehrich, Prince of the
                                Air." By the age of eleven, he had begun working as an apprentice locksmith.
                            </p>

                            <VideoAutoplayObserver class="lg:px-[3vw]">
                                <BaseCard
                                    caption="Houdini Brought Back to Life: A Deepfake Encounter"
                                    link-text="Interval Magic"
                                    source-text="YouTube"
                                    url="https://www.youtube.com/watch?v=cexCQ-3bTrE"
                                    :favicon="require('@/assets/images/favicons/intervalmagic.jpg')"
                                    aspect-ratio-classes="aspect-w-1 aspect-h-1"
                                >
                                    <video
                                        playsinline
                                        muted
                                        loop
                                        :src="require('@/assets/videos/houdini-deepfake.mp4')"
                                    ></video>
                                </BaseCard>
                            </VideoAutoplayObserver>
                        </div>

                        <div class="col-span-10 sm:col-span-5 lg:col-span-4">
                            <p
                                class="capitalized-initial text-center mx-auto my-20 lg:my-40 lg:px-12 lg:mr-[-10vw] lg:ml-[10vw]"
                            >
                                When he was fifteen years old, Houdini stumbled upon the magician's book, "Memoirs of
                                Robert-Houdin", written by himself. Captivated by its contents, he stayed up all night
                                reading it.
                                <br />
                                <br />
                                Houdini would later attribute his profound passion for magic to this pivotal literary
                                discovery, he began calling himself "Houdini" incorrectly believing that an i at the end
                                of a name meant "like" in French. However, "i" at the end of the name means "belong to"
                                in Hungarian.
                            </p>
                        </div>

                        <div class="col-span-10 sm:col-span-5 lg:col-span-3 lg:col-start-7 lg:mt-32">
                            <VideoAutoplayObserver>
                                <BaseCard
                                    caption="The Secret Life of Houdini | History's Greatest Mysteries"
                                    link-text="History.com"
                                    source-text="YouTube"
                                    url="https://www.youtube.com/watch?v=In_kRxOc7Hs&t=2s"
                                    :favicon="require('@/assets/images/favicons/wildabouthoudini.jpg')"
                                    aspect-ratio-classes="aspect-w-1 aspect-h-1"
                                >
                                    <video
                                        playsinline
                                        muted
                                        loop
                                        :src="require('@/assets/videos/secret-life-of-houdini.mp4')"
                                    ></video>
                                </BaseCard>
                            </VideoAutoplayObserver>
                        </div>

                        {{/* * */}}
                        {{/* * */}}
                        {{/* * */}}
                        {{/* * */}}
                        {{/* * */}}
                        {{/* * */}}
                        {{/* * */}}
                        {{/* * */}}
                        {{/* * */}}
                        {{/* * */}}
                        {{/* * */}}
                        {{/* * */}}
                        {{/* * */}}
                        {{/* * */}}
                        {{/* * */}}

                        <div class="col-span-10 my-20 lg:my-[5vw]">
                            <MelkwegBanner />
                        </div>

                        {{/* * */}}
                        {{/* * */}}
                        {{/* * */}}
                        {{/* * */}}
                        {{/* * */}}
                        {{/* * */}}
                        {{/* * */}}
                        {{/* * */}}
                        {{/* * */}}
                        {{/* * */}}
                        {{/* * */}}
                        {{/* * */}}
                        {{/* * */}}
                        {{/* * */}}
                        {{/* * */}}
                    </div>

                    <div class="wall-of-houdini-grid">
                        <p class="capitalized-initial text-center lg:mt-24">
                            In 1887, Rabbi Weiss moved with Erik to New York City, where they lived in a boarding house
                            on East 79th Street. He was joined by the rest of the family once Rabbi Weiss found
                            permanent housing. As a child, Erik Weiss took several jobs, making his public début as a
                            nine-year-old trapeze artist, calling himself "Ehrich, the Prince of the Air".
                        </p>

                        <BaseCard
                            caption="He was rated one of the strongest cross-country runners"
                            link-text="Wild about Houdini"
                            source-text="A blog by Houdini expert John Cox"
                            url="http://www.houdinifile.com/2012/05/who-was-houdinis-father-new-insights.html"
                            :favicon="require('@/assets/images/favicons/houdinilife.png')"
                            aspect-ratio-classes="aspect-w-3 aspect-h-4"
                        >
                            <img :src="require('@/assets/images/athlete.jpg')" alt="cross-country runner" />
                        </BaseCard>

                        <BaseCard
                            class="lg:mt-12"
                            caption="Young Harry, the Athlete (1890)"
                            link-text="Science History Images"
                            source-text="Alamy Stocks"
                            :favicon="require('@/assets/images/favicons/alamy.png')"
                            url="https://www.alamy.com/young-harry-houdini-1890-image416776007
                                .html?imageid=D5C9733D-0D5F-49A0-8757-C39062FF347F&p=471895&pn=1&searchId=2cdbcaeac0055ce1847e9e4467fa4f07&searchtype=0"
                            aspect-ratio-classes="aspect-w-1 aspect-h-2"
                        >
                            <img :src="require('@/assets/images/athlete-2.png')" alt="cross-country runner" />
                        </BaseCard>

                        <QuoteCard
                            text="Keep up your enthusiasm! There is nothing more contagious than exuberant enthusiasm."
                        />

                        <VideoAutoplayObserver>
                            <BaseCard
                                caption="Houdini in a deepfake animation"
                                link-text="adrie van oosterhout"
                                source-text="YouTube"
                                :favicon="require('@/assets/images/favicons/adriene.jpg')"
                                url="https://www.youtube.com/watch?v=JiR5S3INrno"
                                aspect-ratio-classes="aspect-w-1 aspect-h-1"
                            >
                                <video
                                    playsinline
                                    muted
                                    loop
                                    :src="require('@/assets/videos/houdini-deepfake-4.mp4')"
                                ></video>
                            </BaseCard>
                        </VideoAutoplayObserver>

                        <p class="capitalized-initial text-center py-12 lg:py-20">
                            In 1891, the Brothers Houdini showcased their repertoire of card tricks, coin manipulations,
                            and vanishing acts at Huber’s Museum in New York City, as well as at Coney Island during the
                            summer months. By 1893, the Brothers Houdini secured a performance slot outside the World's
                            Fair in Chicago. During this time, Hyman departed from the act, and Houdini's actual
                            brother, Theo ("Dash"), stepped in as his replacement.
                        </p>

                        <VideoAutoplayObserver>
                            <BaseCard
                                caption="Tales of Bizarre and Brazen Harry Houdini Exploits"
                                link-text="Weird History"
                                source-text="YouTube"
                                :favicon="require('@/assets/images/favicons/weird-history.jpg')"
                                url="https://youtu.be/-RY3OcFVz1A?si=tX2xo9sRFrHrV0AL&t=28"
                                aspect-ratio-classes="aspect-w-4 aspect-h-3"
                            >
                                <video playsinline muted loop :src="require('@/assets/videos/tales.mp4')"></video>
                            </BaseCard>
                        </VideoAutoplayObserver>

                        <BaseCard
                            caption="Houdini: Unlocking the Mystery"
                            link-text="TV Mini Series"
                            source-text="IMDb"
                            url="https://www.imdb.com/title/tt0487912/"
                            :favicon="require('@/assets/images/favicons/imdb.png')"
                            aspect-ratio-classes="aspect-w-2 aspect-h-3"
                        >
                            <img
                                :src="require('@/assets/images/unlocking-the-mistery.jpg')"
                                alt="Houdini: Unlocking the Mystery"
                            />
                        </BaseCard>

                        <BaseCard
                            caption="Houdini performs a rope escape, left, and a card trick in these undated photos."
                            link-text="MPR News"
                            source-text="mprnews.org"
                            url="https://www.mprnews.org/story/2016/02/02/books-witch-of-lime-street"
                            :favicon="require('@/assets/images/favicons/mprnews.png')"
                            aspect-ratio-classes="aspect-w-4 aspect-h-3"
                        >
                            <img
                                :src="require('@/assets/images/rope-card-houdini.jpeg')"
                                alt="Houdini performs a rope escape"
                            />
                        </BaseCard>

                        <QuoteCard text="Failure is not the end, but rather a stepping stone on the path to success." />

                        <VideoAutoplayObserver>
                            <BaseCard
                                caption="Harry Houdini – Escape Artist and Marketing Genius"
                                link-text="Biographics"
                                source-text="YouTube"
                                :favicon="require('@/assets/images/favicons/biographics.jpg')"
                                url="https://www.youtube.com/watch?v=WPLnev8E5xg&t=14s"
                                aspect-ratio-classes="aspect-w-4 aspect-h-3"
                            >
                                <video
                                    playsinline
                                    muted
                                    loop
                                    :src="require('@/assets/videos/escaping-artist-and-marketing-genius.mp4')"
                                ></video>
                            </BaseCard>
                        </VideoAutoplayObserver>

                        <p class="capitalized-initial text-center py-12 lg:py-20">
                            A romance blossomed between the 20-year-old Houdini and 18-year-old Wilhelmina Beatrice
                            (“Bess”) Rahner, who was part of the singing and dancing duo known as the Floral Sisters.
                            After a three-week courtship, she and Houdini married, with Bess replacing Dash in the act,
                            which became known as "The Houdinis". For the rest of Houdini's performing career, Bess
                            worked as his stage assistant.
                        </p>

                        <BaseCard
                            caption="Poster Of The Houdinis Performing Metamorphosis"
                            link-text="Everett Collection"
                            source-text="Posterazzi.com"
                            url="https://www.posterazzi.com/"
                            :favicon="require('@/assets/images/favicons/postezzarri.jpg')"
                        >
                            <img
                                :src="require('@/assets/images/metamorphosis.jpg')"
                                alt="Houdini: Unlocking the Mystery"
                            />
                        </BaseCard>

                        <QuoteCard text="An old trick well done is far better than a new trick with no effect." />

                        <BaseCard
                            caption="Houdini was a master mind of building publicity, a relentless self-promoter"
                            link-text="Original poster"
                            source-text="Remastered by Carlos Diaz"
                            url="https://pixels.com/featured/houdini-poster-carlos-diaz.html"
                            :favicon="require('@/assets/images/favicons/px.png')"
                        >
                            <img
                                :src="require('@/assets/images/brace-cert.png')"
                                alt="Houdini: Unlocking the Mystery"
                            />
                        </BaseCard>

                        <BaseCard
                            caption="Houdini was a great writer too, Strange Tales is a collection of fictions"
                            link-text="7 short stories, 3 one-act plays, and 8 film treatments."
                            source-text="Kieran Press"
                            url="https://www.martinsmagic.com/allmagic/books/houdinis-strange-tales-by-harry-houdini/"
                            :favicon="require('@/assets/images/favicons/martins-magic-favicon.jpg')"
                        >
                            <img
                                :src="require('@/assets/images/houdinis-strange-tales.jpeg')"
                                alt="Houdini was a great writer too"
                            />
                        </BaseCard>

                        <QuoteCard
                            text="Never try to fool children, they expect nothing, and therefore see everything..."
                        />

                        <VideoAutoplayObserver>
                            <BaseCard
                                caption="Houdini's own introductory words for the famous Water Torture Cell act from 1914"
                                link-text="The Original Harry Houdini's Voice"
                                source-text="YouTube"
                                :favicon="require('@/assets/images/favicons/houdinimuseum.jpg')"
                                url="https://www.youtube.com/watch?v=D50yh3WV-OQ"
                                aspect-ratio-classes="aspect-w-4 aspect-h-3"
                            >
                                <video playsinline muted loop :src="require('@/assets/videos/voice.mp4')"></video>
                            </BaseCard>
                        </VideoAutoplayObserver>

                        <BaseCard
                            caption="Diving into the Chinese water torture chamber"
                            link-text="Hungarytoday.hu"
                            source-text="Photo Source: Wikimedia"
                            url="https://hungarytoday.hu/anniversary-remembering-the-one-and-only-harry-houdini/"
                            :favicon="require('@/assets/images/favicons/hungary-today.png')"
                        >
                            <img
                                :src="require('@/assets/images/Houdini-water-chamber.jpeg')"
                                alt="Diving into the Chinese water torture chamber"
                            />
                        </BaseCard>

                        <VideoAutoplayObserver>
                            <BaseCard
                                caption="The water torture cell escape was arguably Houdini’s most memorable stunt. Here is a video to explain the myth behind."
                                link-text="From the Series: The Curious Life and Death Of"
                                source-text="YouTube"
                                :favicon="require('@/assets/images/favicons/smithsonian.jpg')"
                                url="https://www.youtube.com/watch?v=mbBF_3WbrRk"
                                aspect-ratio-classes="aspect-w-4 aspect-h-3"
                            >
                                <video playsinline muted loop :src="require('@/assets/videos/dangerous.mp4')"></video>
                            </BaseCard>
                        </VideoAutoplayObserver>

                        <p class="capitalized-initial text-center py-12 lg:py-20">
                            In the spring of 1900, at the age of 26, Houdini, radiating confidence under the moniker
                            "The King of Handcuffs," set out for Europe with hopes of achieving success. His inaugural
                            destination was London, where he graced the stage of the Alhambra Theater. During his time
                            there, Houdini faced a challenge to free himself from Scotland Yard’s handcuffs. True to
                            form, he successfully escaped, drawing crowds to the theater every night for months on end.
                        </p>

                        <BaseCard
                            caption="The Secret Life of Houdini: The Making of America's First Superhero"
                            link-text="Biography book (2007)"
                            source-text="Amazon"
                            url="https://www.amazon.com/Secret-Life-Houdini-Americas-Superhero/dp/0743272080"
                            :favicon="require('@/assets/images/favicons/amazon.png')"
                        >
                            <img :src="require('@/assets/images/houdini-book.jpg')" alt="The Secret Life of Houdini" />
                        </BaseCard>

                        <BaseCard
                            caption="Like a modern advertisement"
                            link-text="Library of Congress"
                            source-text="smithsonianmag.com"
                            url="https://www.smithsonianmag.com/arts-culture/houdini-revealed-151494397/"
                            :favicon="require('@/assets/images/favicons/smithsonian.jpg')"
                        >
                            <img :src="require('@/assets/images/Houdini_portrait.png')" alt="Houdini portrait" />
                        </BaseCard>

                        <VideoAutoplayObserver>
                            <BaseCard
                                caption="How Did Harry Houdini Escape From A Locked Russian Prison Carette?"
                                link-text="Discovery UK"
                                source-text="YouTube"
                                :favicon="require('@/assets/images/favicons/discovery-uk.jpg')"
                                url="https://www.youtube.com/watch?v=pTmL0q5DpkY"
                                aspect-ratio-classes="aspect-w-4 aspect-h-3"
                            >
                                <video
                                    playsinline
                                    muted
                                    loop
                                    :src="require('@/assets/videos/how-did-harry-houdini-escape.mp4')"
                                ></video>
                            </BaseCard>
                        </VideoAutoplayObserver>

                        <BaseCard
                            caption="The young couple, Bess & Harry worked as The Houdinis for several years before Houdini hit it big as The Handcuff King. But he and Bess continued to occasionally perform their signature trick, Metamorphosis, throughout his career."
                            link-text="1895 poster for The Houdinis"
                            source-text="Wikipedia"
                            url="https://en.m.wikipedia.org/wiki/Bess_Houdini"
                            :favicon="require('@/assets/images/favicons/smithsonian.jpg')"
                        >
                            <img :src="require('@/assets/images/Houdinis_poster.jpeg')" alt="Bess & Harry" />
                        </BaseCard>

                        <QuoteCard
                            text="I always have on my mind the thought that next year I must do something greater, something more wonderful."
                        />

                        <BaseCard
                            caption="“My Two Sweethearts” – Houdini with his mother and wife, Beatrice (circa 1907)"
                            link-text="Wikimedia"
                            source-text="Wikimedia"
                            url="https://en.m.wikipedia.org/wiki/File:Weiss_with_mother_and_wife.jpg"
                            :favicon="require('@/assets/images/favicons/wikipedia.png')"
                        >
                            <img
                                :src="require('@/assets/images/Weiss_with_mother_and_wife.jpg')"
                                alt="Houdini with his mother and wife"
                            />
                        </BaseCard>

                        <BaseCard
                            caption="Playing with cards"
                            link-text="Courtesy of the Sidney H. Radner Collection"
                            source-text="myhistorymuseum.org"
                            url="http://www.myhistorymuseum.org/houdini-history.html"
                            :favicon="require('@/assets/images/favicons/myhistorymuseum.jpg')"
                        >
                            <img :src="require('@/assets/images/card_trick.png')" alt="Houdini doing card trick" />
                        </BaseCard>

                        <BaseCard
                            caption="Houdini poster archive"
                            link-text="Library of Congress"
                            source-text="loc.gov"
                            url="https://www.loc.gov/resource/cph.3f03785/"
                            :favicon="require('@/assets/images/favicons/loc.png')"
                        >
                            <img :src="require('@/assets/images/poster-2.png')" alt="Houdini poster archive" />
                        </BaseCard>

                        <VideoAutoplayObserver>
                            <BaseCard
                                caption="Harry Houdini makes first controlled airplane flight in Australia - 1910"
                                link-text="My Footage"
                                source-text="YouTube"
                                :favicon="require('@/assets/images/favicons/my_footage.jpg')"
                                url="https://www.youtube.com/watch?v=YtW36LBfoOY"
                                aspect-ratio-classes="aspect-w-4 aspect-h-3"
                            >
                                <video
                                    playsinline
                                    muted
                                    loop
                                    :src="require('@/assets/videos/houdini-australia-first-flight.mp4')"
                                ></video>
                            </BaseCard>
                        </VideoAutoplayObserver>

                        <BaseCard
                            caption="A poster remake"
                            link-text="Movie Posters"
                            source-text="movieposters.com"
                            url="https://www.movieposters.com/products/houdini-harry-mpw-118525"
                            :favicon="require('@/assets/images/favicons/movieposters.png')"
                        >
                            <img
                                :src="require('@/assets/images/chinese_torture_chamber.png')"
                                alt="Houdini chinese torture chamber poster"
                            />
                        </BaseCard>

                        <VideoAutoplayObserver>
                            <BaseCard
                                caption="The Legendary Straight Jacket Escape (1914)"
                                link-text="AllAboutMagicians"
                                source-text="YouTube"
                                :favicon="require('@/assets/images/favicons/allaboutmagicians.jpg')"
                                url="https://www.youtube.com/watch?v=3r8qr-p9z5g"
                                aspect-ratio-classes="aspect-w-4 aspect-h-3"
                            >
                                <video
                                    playsinline
                                    muted
                                    loop
                                    :src="require('@/assets/videos/straight-jacket-escape.mp4')"
                                ></video>
                            </BaseCard>
                        </VideoAutoplayObserver>

                        <QuoteCard text="To be a great magician, you must master the art of misdirection." />

                        <BaseCard
                            caption="Harry Houdini on a US postcard from 1914"
                            link-text="historyloversofficial"
                            source-text="Instagram"
                            url="https://www.instagram.com/p/Cy4uos7JTcY/"
                            :favicon="require('@/assets/images/favicons/instagram.png')"
                        >
                            <img
                                :src="require('@/assets/images/Houdini_1914Postcard.png')"
                                alt="Houdini poster archive"
                            />
                        </BaseCard>

                        <VideoAutoplayObserver>
                            <BaseCard
                                caption="Man Sent To Prison, But Escapes In Just 5 Minutes"
                                link-text="Mystery Recapped"
                                source-text="YouTube"
                                :favicon="require('@/assets/images/favicons/mistery_recapped.jpg')"
                                url="https://www.youtube.com/watch?v=OrHrjh5dzWY"
                                aspect-ratio-classes="aspect-w-16 aspect-h-9"
                            >
                                <video
                                    playsinline
                                    muted
                                    loop
                                    :src="require('@/assets/videos/man_sent_to_prison.mp4')"
                                ></video>
                            </BaseCard>
                        </VideoAutoplayObserver>

                        <p class="capitalized-initial text-center py-12 lg:py-20">
                            Houdini became renowned for several daring escapes throughout his career, including the
                            Daily Mirror challenge, the Milk Can Escape, the Chinese Water Torture Cell, the
                            Straitjacket Escape, and the Buried Alive stunt.
                        </p>

                        <BaseCard
                            caption="The Master Mystery is a 1918 American mystery silent serial film told in 15 installments, starring Houdini. His showmanship did not translate in film, and his movies proved to be failures."
                            link-text="The Master Mystery"
                            source-text="Wikipedia"
                            url="https://www.imdb.com/title/tt0010446/"
                            :favicon="require('@/assets/images/favicons/imdb.png')"
                        >
                            <img :src="require('@/assets/images/the_master_mistery.jpg')" alt="The master mystery" />
                        </BaseCard>

                        <VideoAutoplayObserver>
                            <BaseCard
                                caption="Houdini Bridge Jump with Handcuff Escape in Rochester, NY"
                                link-text="David Folender"
                                source-text="YouTube"
                                :favicon="require('@/assets/images/favicons/david_folender.jpg')"
                                url="https://www.youtube.com/watch?v=E_HHFMSmHCQ"
                                aspect-ratio-classes="aspect-w-4 aspect-h-3"
                            >
                                <video
                                    playsinline
                                    muted
                                    loop
                                    :src="require('@/assets/videos/bridge_handcuff_escape.mp4')"
                                ></video>
                            </BaseCard>
                        </VideoAutoplayObserver>

                        <QuoteCard
                            text="I am not an irretrievable skeptic. I am not hopelessly prejudiced. I am perfectly willing to believe, and my mind is wide open; but I have, as yet, to be convinced. I am perfectly willing, but the evidence must be sane and conclusive."
                        />

                        <BaseCard
                            caption="Vintage Movie poster for The Master of Mystery - circa 1919"
                            link-text="French School"
                            source-text="Fine Art America"
                            url="https://fineartamerica.com/featured/harry-houdini-vintage-movie-poster-for-the-master-of-mystery-circa-1919-french-school.html"
                            :favicon="require('@/assets/images/favicons/fineartamerica.png')"
                        >
                            <img
                                :src="
                                    require('@/assets/images/harry-houdini-vintage-movie-poster-for-the-master-of-mystery-circa-1919-french-school.jpg')
                                "
                                alt="poster of the Master of Mystery"
                            />
                        </BaseCard>

                        <p class="capitalized-initial text-center py-12 lg:py-20">
                            Houdini was not only a renowned illusionist but also a passionate advocate for the art of
                            magic. He played a pivotal role in its founding and early years of The Society of American
                            Magicians (SAM), served as its first president and was instrumental in shaping its mission
                            to promote the art of magic, foster good-fellowship among magicians, and uphold ethical
                            standards within the profession.
                        </p>

                        <VideoAutoplayObserver>
                            <BaseCard
                                caption="The Man who fooled Houdini, who claimed that, if he watched any illusion for 3 times, he could figure it out. Not until his 'merry meeting' with The Professor Dai Vernon..."
                                link-text="Jeremy Tan"
                                source-text="YouTube"
                                :favicon="require('@/assets/images/favicons/jeremy_tan.jpg')"
                                url="https://www.youtube.com/watch?v=rSFVZJtq91I"
                                aspect-ratio-classes="aspect-w-4 aspect-h-3"
                            >
                                <video
                                    playsinline
                                    muted
                                    loop
                                    :src="require('@/assets/videos/the_man_who_fooled_harry_houdini.mp4')"
                                ></video>
                            </BaseCard>
                        </VideoAutoplayObserver>

                        <BaseCard
                            caption="Archive poster"
                            link-text="Library of Congress"
                            source-text="loc.gov"
                            url="https://www.loc.gov/item/2014636902/"
                            :favicon="require('@/assets/images/favicons/loc.png')"
                        >
                            <img :src="require('@/assets/images/houdini_spirits_poster.png')" alt="Archive poster" />
                        </BaseCard>

                        <BaseCard
                            caption="The Grim Game is a 1919 American silent drama starring Harry Houdini"
                            link-text="Original movie poster"
                            source-text="Wikipedia"
                            url="https://en.wikipedia.org/wiki/The_Grim_Game"
                            :favicon="require('@/assets/images/favicons/wikipedia.png')"
                        >
                            <img :src="require('@/assets/images/grim.jpg')" alt="The Grim Game poster" />
                        </BaseCard>

                        <VideoAutoplayObserver>
                            <BaseCard
                                caption="Houdini Straightjacket Escape - Houston Texas (1923)"
                                link-text="David Folender"
                                source-text="YouTube"
                                :favicon="require('@/assets/images/favicons/david_folender.jpg')"
                                url="https://www.youtube.com/watch?v=jbwcYttRZAU"
                                aspect-ratio-classes="aspect-w-4 aspect-h-3"
                            >
                                <video
                                    playsinline
                                    muted
                                    loop
                                    :src="require('@/assets/videos/houdini_straightjacket_escape.mp4')"
                                ></video>
                            </BaseCard>
                        </VideoAutoplayObserver>

                        <QuoteCard text="What the eyes sees, the ear hears, and the mind believes." />

                        <VideoAutoplayObserver>
                            <BaseCard
                                caption="Houdini underwater rope escape"
                                link-text="Wild about Harry"
                                source-text="YouTube"
                                :favicon="require('@/assets/images/favicons/wildabouthoudini.jpg')"
                                url="https://www.youtube.com/watch?v=jbwcYttRZAU"
                                aspect-ratio-classes="aspect-w-4 aspect-h-3"
                            >
                                <video
                                    playsinline
                                    muted
                                    loop
                                    :src="require('@/assets/videos/houdini_underwater_rope.mp4')"
                                ></video>
                            </BaseCard>
                        </VideoAutoplayObserver>

                        <BaseCard
                            caption="Archive poster"
                            link-text="Library of Congress"
                            source-text="loc.gov"
                            url="https://www.loc.gov/item/2014636902/"
                            :favicon="require('@/assets/images/favicons/loc.png')"
                        >
                            <img :src="require('@/assets/images/houdini_spirits_poster.png')" alt="Archive poster" />
                        </BaseCard>

                        <BaseCard
                            caption="Harry Houdini’s 400 year-old Magic Book!"
                            link-text="moonsrarebooks"
                            source-text="Instagram"
                            url="https://www.instagram.com/reel/C37nyELucSi/"
                            :favicon="require('@/assets/images/favicons/instagram.png')"
                        >
                            <img
                                :src="require('@/assets/images/houdinis_magic_book.jpg')"
                                alt="Harry Houdini’s 400 year-old Magic Book"
                            />
                        </BaseCard>

                        <VideoAutoplayObserver>
                            <BaseCard
                                caption="Was Houdini's Mystifying Magic ACTUALLY Real?"
                                link-text="HISTORY"
                                source-text="YouTube"
                                :favicon="require('@/assets/images/favicons/history.jpg')"
                                url="https://www.youtube.com/watch?v=AczbXsYdh6Q"
                                aspect-ratio-classes="aspect-w-16 aspect-h-9"
                            >
                                <video
                                    playsinline
                                    muted
                                    loop
                                    :src="require('@/assets/videos/houdini_special.mp4')"
                                ></video>
                            </BaseCard>
                        </VideoAutoplayObserver>

                        <VideoAutoplayObserver>
                            <BaseCard
                                caption="The Life And Magic Of The Real Harry Houdini"
                                link-text="Timeline - World History Documentaries"
                                source-text="YouTube"
                                :favicon="require('@/assets/images/favicons/timeline.jpg')"
                                url="https://www.youtube.com/watch?v=XUhJLs3-i9M"
                                aspect-ratio-classes="aspect-w-4 aspect-h-3"
                            >
                                <video
                                    playsinline
                                    muted
                                    loop
                                    :src="require('@/assets/videos/life_and_magic_of_real_harry.mp4')"
                                ></video>
                            </BaseCard>
                        </VideoAutoplayObserver>

                        <VideoAutoplayObserver>
                            <BaseCard
                                caption="Houdini documentary by PBS"
                                link-text="PBS America"
                                source-text="YouTube"
                                :favicon="require('@/assets/images/favicons/george_pollen.jpg')"
                                url="https://www.youtube.com/watch?v=iUMVVHcRxiY&t=849s"
                                aspect-ratio-classes="aspect-w-4 aspect-h-3"
                            >
                                <video playsinline muted loop :src="require('@/assets/videos/houdini_52.mp4')"></video>
                            </BaseCard>
                        </VideoAutoplayObserver>

                        <BaseCard
                            caption="Rabbit Entertain Five Hospitalized Children (1925)"
                            link-text="Everett Collection"
                            source-text="Posterazzi"
                            url="https://www.posterazzi.com/harry-houdini-and-his-rabbit-entertain-five-hospitalized-children-ca-1925-history-item-varevchisl011ec109/"
                            :favicon="require('@/assets/images/favicons/postezzarri.jpg')"
                        >
                            <img
                                :src="require('@/assets/images/houdini_children_hospital.jpg')"
                                alt="Rabbit Entertain Five Hospitalized Children (1925)"
                            />
                        </BaseCard>

                        <QuoteCard text="The greatest escape I ever made was when I left Appleton, Wisconsin." />

                        <VideoAutoplayObserver>
                            <BaseCard
                                caption="The Suspicious Death Of Harry Houdini"
                                link-text="BuzzFeed"
                                source-text="YouTube"
                                :favicon="require('@/assets/images/favicons/buzzfeed_unsolved.jpg')"
                                url="https://www.youtube.com/watch?v=Bj6ulLJGPB8"
                                aspect-ratio-classes="aspect-w-4 aspect-h-3"
                            >
                                <video
                                    playsinline
                                    muted
                                    loop
                                    :src="require('@/assets/videos/houdini_suspicious_death.mp4')"
                                ></video>
                            </BaseCard>
                        </VideoAutoplayObserver>

                        <BaseCard
                            caption="Death certificate"
                            link-text="Archives of Michigan"
                            source-text="michiganology.org"
                            url="https://michiganology.org/stories/stories/"
                            :favicon="require('@/assets/images/favicons/michiganology.png')"
                        >
                            <img :src="require('@/assets/images/death_cert.jpeg')" alt="Houdini's death certificate" />
                        </BaseCard>

                        <BaseCard
                            caption="Given that Houdini spent a considerable portion of his life performing in New York City, it's rather unsurprising that his ultimate resting spot was the Jewish Machpelah Cemetery in Queens."
                            link-text="Find a Grave"
                            source-text="findagrave.com"
                            url="https://www.findagrave.com/memorial/509/harry-houdini"
                            :favicon="require('@/assets/images/favicons/findagrave.png')"
                        >
                            <img :src="require('@/assets/images/grave.jpeg')" alt="Houdini's grave" />
                        </BaseCard>

                        <VideoAutoplayObserver>
                            <BaseCard
                                caption="How He Died and Getting Lost in a Cemetery – A Walk in the Machpelah Cemetery"
                                link-text="Scott on Tape"
                                source-text="YouTube"
                                :favicon="require('@/assets/images/favicons/scott_on_tape.jpg')"
                                url="https://www.youtube.com/watch?v=H8RGIXbOivM"
                                aspect-ratio-classes="aspect-w-4 aspect-h-3"
                            >
                                <video
                                    playsinline
                                    muted
                                    loop
                                    :src="require('@/assets/videos/houdinis_grave.mp4')"
                                ></video>
                            </BaseCard>
                        </VideoAutoplayObserver>

                        <QuoteCard
                            text="No performer should attempt to bite off red-hot iron unless he has a good set of teeth."
                        />

                        <VideoAutoplayObserver>
                            <BaseCard
                                caption="Books About Houdini and by Houdini"
                                link-text="CollectingMagicBooks"
                                source-text="YouTube"
                                :favicon="require('@/assets/images/favicons/magic_books.jpg')"
                                url="https://www.youtube.com/watch?v=-GG_59DrEUU"
                                aspect-ratio-classes="aspect-w-4 aspect-h-3"
                            >
                                <video playsinline muted loop :src="require('@/assets/videos/magic_books.mp4')"></video>
                            </BaseCard>
                        </VideoAutoplayObserver>
                    </div>
                </div>

                <div class="col-span-10">
                    <FundRaising />
                    <Footer :date="`${humanizedDate}, ${romanizedYear}`" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { throttle } from 'throttle-debounce';
import romanize from 'romanize';
import FundRaising from '@/components/Blocks/FundRaising.vue';
import BaseCard from '@/components/Cards/BaseCard.vue';
import QuoteCard from '@/components/Cards/QuoteCard.vue';
import VideoAutoplayObserver from '@/components/Cards/VideoAutoplayObserver.vue';
import AnimatedText from '@/components/Blocks/AnimatedText.vue';
import ElectricksPromo from '@/components/Blocks/ElectricksPromo.vue';
import MelkwegBanner from '@/components/Blocks/MelkwegBanner.vue';
import Footer from '@/components/Layout/Footer.vue';

export default {
    name: 'Index',
    components: {
        Footer,
        ElectricksPromo,
        AnimatedText,
        BaseCard,
        FundRaising,
        MelkwegBanner,
        QuoteCard,
        VideoAutoplayObserver,
    },
    data() {
        return {
            romanizedYear: 0,
            humanizedDate: '',
            msnry: null,
        };
    },
    mounted() {
        if (window.innerWidth >= 640) {
            this.initMasonry();

            setTimeout(() => {
                this.$refs.mainWrapper.style.transition = 'background-color 1s ease, color 1s ease';
                // just to make sure, update the layout
                this.msnry.layout();
            }, 1000);
        }

        window.addEventListener(
            'resize',
            throttle(200, false, () => {
                if (window.innerWidth >= 640 && !this.msnry) {
                    this.initMasonry();
                } else if (window.innerWidth >= 640) {
                    this.msnry.destroy();
                    this.initMasonry();
                } else if (window.innerWidth < 640 && this.msnry) {
                    this.msnry.destroy();
                }
            })
        );

        const date = new Date();
        this.romanizedYear = romanize(date.getFullYear());

        this.humanizedDate = this.$dayjs().format('dddd, MMMM DD');

        const observer = new IntersectionObserver(
            (entries) => {
                if (!entries[0].isIntersecting) {
                    this.$store.commit('globals/setIsPageBgDark', false);
                } else {
                    this.$store.commit('globals/setIsPageBgDark', true);
                }
            },
            {
                rootMargin: `0px 0px -100% 0px`,
            }
        );

        observer.observe(this.$refs.mainContent);
    },
    methods: {
        initMasonry() {
            this.msnry = new this.$Masonry('.wall-of-houdini-grid', {
                itemSelector: '.wall-of-houdini-grid > *',
                percentPosition: true,
                gutter: this.getMasonryGutter(),
                transitionDuration: 0,
            });
        },
        getMasonryGutter() {
            let gutter = 0;

            if (window.innerWidth >= 640) {
                gutter = 20;
            }

            if (window.innerWidth >= 1024) {
                gutter = 75;
            }

            return gutter;
        },
    },
};
</script>

<style scoped>
.grid > div {
    @apply relative z-1;
}

.wall-of-houdini-grid {
    @apply relative;
}

.wall-of-houdini-grid > * {
    @apply mb-12 lg:mb-20 w-full sm:w-[calc(50%-10px)] lg:w-[calc(33.333%-50px)];
}
</style>
