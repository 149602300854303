<template>
    <a
        :href="to"
        target="_blank"
        rel="noopener noreferrer"
        class="px-4 py-2 border-2 font-bold uppercase transition-colors w-full sm:w-auto text-center"
    >
        <span class="button-label"> ~ {{ label }} ~ </span>
    </a>
</template>

<script>
export default {
    name: 'CustomButton',
    props: {
        to: {
            type: String,
            required: true,
        },
        label: {
            type: String,
            required: false,
            default: null,
        },
    },
};
</script>

<style scoped>
a {
    @apply border-black hover:bg-black hover:text-bone-white;
}
.bg-dark a {
    @apply border-bone-white hover:bg-bone-white hover:text-black;
}
</style>
